<template lang="">
    <div class="container">
        <h1 class="text-center fs-xs-30 px-xs-4">
            Políticas de Privacidade
        </h1>
        <p class="fw-500 text-center mt-4 col-lg-11 px-0 mx-auto fs-sm-17">
           A sua privacidade é importante para nós. É política da ABRAIP Detetives respeitar a sua privacidade
            em relação a qualquer informação sua que possamos coletar no site ABRAIP Detetives, e outros sites
            que possuímos e operamos.
        </p>
        <p class="fw-500 text-center col-lg-11 px-0 mx-auto fs-sm-17">
           Nós coletamos e utilizamos alguns dados pessoais que pertencem aqueles que utilizam nosso site.
           Ao fazê-lo, agimos na qualidade de controlados desses dados e estamos sujeitos às disposições
           da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais – LGPD).
        </p>
        <p class="fw-500 text-center col-lg-11 px-0 mx-auto fs-sm-17">
           Nos cuidamos da proteção dos seus dados pessoais e, por isso, disponibilizamos esta política de privacidade,
           que contém informações importantes sobre:
        </p>
        <p class="fw-500 text-center col-lg-11 px-0 mx-auto fs-sm-17">
           - Quais dados coletamos e o que fazemos com eles;
        </p>
        <p class="fw-500 text-center col-lg-11 px-0 mx-auto fs-sm-17">
           - Seus direitos em relação aos seus dados pessoais;
        </p>
        <p class="fw-500 text-center col-lg-11 px-0 mx-auto fs-sm-17">
           - Como entrar em contato conosco.
        </p>
        <p class="fw-500 text-center col-lg-11 px-0 mx-auto fs-sm-17">
           Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço.
           Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos
           coletando e como será usado.
        </p>
        <p class="fw-500 text-center col-lg-11 px-0 mx-auto fs-sm-17">
           Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos
           dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como acesso,
           divulgação, cópia, uso ou modificação não autorizados.
        </p>
        <p class="fw-500 text-center col-lg-11 px-0 mx-auto fs-sm-17">
           Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido
           por lei
        </p>
        <p class="fw-500 text-center col-lg-11 px-0 mx-auto fs-sm-17">
           Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos
           fornecer alguns dos serviços desejados.
        </p>
        <p class="fw-500 text-center col-lg-11 px-0 mx-auto fs-sm-17">
           O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade
           e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações
           pessoais, entre em contacto conosco.
        </p>
        <h2 class="text-center fs-xs-25 mt-50 px-xs-4">
            Política de Cookies. O que são Cookies?
        </h2>
        <p class="fw-500 text-center mt-4 col-lg-11 px-0 mx-auto fs-sm-17">
            Como é prática comum em quase todos os sites profissionais, este site usa cookies, que são pequenos arquivos
            baixados no seu computador, para melhorar sua experiência. Esta página descreve quais informações eles coletam,
            como as usamos e por que às vezes precisamos armazenar esses cookies. Também compartilharemos como você pode impedir
            que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou 'quebrar' certos elementos da
            funcionalidade do site.
        </p>
        <h2 class="text-center fs-xs-25 mt-50 px-xs-4">
            Como usamos os Cookies?
        </h2>
        <p class="fw-500 text-center mt-4 col-lg-11 px-0 mx-auto fs-sm-17">
            Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na maioria dos casos, não existem
            opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos
            que eles adicionam a este site. É recomendável que você deixe todos os cookies se não tiver certeza se precisa
            ou não deles, caso sejam usados para fornecer um serviço que você usa.
        </p>
        <h2 class="text-center fs-xs-25 mt-50 px-xs-4">
            Desativar Cookies?
        </h2>
        <p class="fw-500 text-center mt-4 col-lg-11 px-0 mx-auto fs-sm-17">
            Você pode impedir a configuração de cookies ajustando as configurações do seu navegador (consulte a
            Ajuda do navegador para saber como fazer isso). Esteja ciente de que a desativação de cookies afetará
            a funcionalidade deste e de muitos outros sites que você visita. A desativação de cookies geralmente
            resultará na desativação de determinadas funcionalidades e recursos deste site. Portanto, é recomendável
            que você não desative os cookies.
        </p>
        <h2 class="text-center fs-xs-25 mt-50 px-xs-4">
            Cookies relacionados a formulários?
        </h2>
        <p class="fw-500 text-center mt-4 col-lg-11 px-0 mx-auto fs-sm-17">
            Quando você envia dados por meio de um formulário como os encontrados nas páginas de contacto ou nos
            formulários de comentários, os cookies podem ser configurados para lembrar os detalhes do usuário para
            correspondência futura.
        </p>
        <h2 class="text-center fs-xs-25 mt-50 px-xs-4">
            Cookies do Google Analytics
        </h2>
        <p class="fw-500 text-center mt-4 col-lg-11 px-0 mx-auto fs-sm-17">
            Este site usa o Google Analytics, que é uma das soluções de análise mais difundidas e
            confiáveis da Web, para nos ajudar a entender como você usa o site e como podemos melhorar sua experiência.
            Esses cookies podem rastrear itens como quanto tempo você gasta no site e as páginas visitadas, para que possamos
            continuar produzindo conteúdo atraente.
        </p>
        <h2 class="text-center fs-xs-25 mt-50 px-xs-4">
            Compromisso do usuário
        </h2>
        <p class="fw-500 text-center mt-4 col-lg-11 px-0 mx-auto fs-sm-17">
            O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o ABRAIP Detetives
            oferece no site e com caráter enunciativo, mas não limitativo:
        </p>
        <p class="fw-500 text-center col-lg-11 px-0 mx-auto fs-sm-17">
            <b>A)</b> Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;
        </p>
        <p class="fw-500 text-center col-lg-11 px-0 mx-auto fs-sm-17">
            <b>B)</b> Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, ou casas de apostas legais
            (ex.: Betano), jogos de sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo
            ou contra os direitos humanos;
        </p>
        <p class="fw-500 text-center col-lg-11 px-0 mx-auto fs-sm-17">
            <b>C)</b> Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do ABRAIP Detetives, de seus
            fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas
            de hardware ou software que sejam capazes de causar danos anteriormente mencionados.
        </p>
        <h2 class="text-center fs-xs-25 mt-50 px-xs-4">
            Como entrar em contato conosco
        </h2>
        <p class="fw-500 text-center mt-4 col-lg-11 px-0 mx-auto fs-sm-17">
            Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados
            pessoais que tratamos, entre em contato com nosso Analista de Proteção de Dados Pessoais,
            através do e-mail: abraipdetetives@gmail.com
        </p>
        <h2 class="text-center fs-xs-25 mt-50 px-xs-4">
            Mais informações
        </h2>
        <p class="fw-500 text-center mt-4 col-lg-11 px-0 mx-auto fs-sm-17">
            Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza
            se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos
            que você usa em nosso site.
        </p>
        <p class="fw-700 text-center text-italic mt-4 col-lg-11 px-0 mx-auto fs-sm-17">
            Esta política é efetiva a partir de Julho/2021.
        </p>
    </div>
</template>
<script>
export default {
  metaInfo: {
    link: [
      { rel: 'alternate', href: 'https://abraip.com.br/politicas-de-privacidade', hreflang: 'x-default' },
      { rel: 'alternate', href: 'https://abraipdetectives.pt/politicas-de-privacidade', hreflang: 'pt-br' },
      { rel: 'alternate', href: 'https://abraip.com.br/politicas-de-privacidade', hreflang: 'pt' }
    ]
  }
}
</script>
<style lang="">

</style>
